import React, { useEffect, useState } from "react";
import ColllectionsCard from "../Home/ColllectionsCard";
import { Link } from "react-router-dom";
import { getAllCollectionsPublicService } from "../../services/collectionsService";
import { MoonLoader } from "react-spinners";
import useCart from "../../hooks/useCart";
// import { rootContext } from "../../context/RootContext";

const AllUserAndCart = () => {
  const [, setData] = useState([]);
  const [CardUser1, setCardUser1] = useState([]); // Assuming this is the list of all users
  const [filteredUsers, setFilteredUsers] = useState([]); // Filtered users state
  // const [url, setUrl] = useState("");
  // const { isCartModal, setIsCartModal } = useContext(rootContext);
  const [loader, setLoader] = useState(false);
  const {
    cartItems,
    url,
    // fetchData
  } = useCart();
  const [filter, setFilter] = useState({
    zipCode: "",
    deliveryMethod: "",
    state: "",
  });

  // Fetch collections data
  const allCollectionsData = async () => {
    setLoader(true);
    const result = await getAllCollectionsPublicService();
    if (result.categorycardCollectionTitle) {
      setData(result.categorycardCollectionTitle);
      setLoader(false);
      // setUrl(result.imagePath);
    } else {
      setLoader(false);
    }
  };

  // Fetch cart items and populate CardUser1
  useEffect(() => {
    if (cartItems?.length > 0) {
      const users = cartItems.flatMap((item) => item?.card?.user || []);
      setCardUser1(users);
      setFilteredUsers(users); // Initially set filtered users to all users
    }
  }, [cartItems]);

  useEffect(() => {
    allCollectionsData();
  }, []);

  // Apply filter based on user input
  useEffect(() => {
    if (CardUser1.length > 0) {
      const filtered = CardUser1.filter((user) => {
        const matchesDeliveryMethod =
          filter?.deliveryMethod &&
          user?.delivery_method?.some((method) =>
            method.key
              .toLowerCase()
              .includes(filter?.deliveryMethod.toLowerCase())
          );
        const matchesState =
          filter?.state &&
          user?.state?.toLowerCase()?.includes(filter?.state.toLowerCase());
        const matchesZipCode =
          filter?.zipCode && user?.zipcode?.includes(filter?.zipCode);

        return (
          (filter?.deliveryMethod ? matchesDeliveryMethod : true) &&
          (filter?.state ? matchesState : true) &&
          (filter?.zipCode ? matchesZipCode : true)
        );
      });
      setFilteredUsers(filtered);
    }
  }, [filter, CardUser1]);

  // Handle filter input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  return (
    <>
      {!loader ? (
        <div className="pt-5">
          <div className="container">
            <div className="row fadeIn">
              <div className="col-lg-12">
                <div className="items_filter w-100">
                  <form className="row form-dark w-100" id="form_quick_search">
                    <div
                      className="col text-center w-100"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <input
                        className="form-control mb-0"
                        style={{ border: "1px solid #ddd" }}
                        id="zipCode"
                        name="zipCode"
                        value={filter.zipCode}
                        onChange={handleInputChange}
                        placeholder="Search Zip Code"
                        type="text"
                      />
                      <select
                        className="form-control mb-0"
                        style={{ width: "300px" }}
                        name="deliveryMethod"
                        value={filter.deliveryMethod}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Delivery Method</option>
                        <option value="Presential">Presential</option>
                        <option value="Courier">Courier</option>
                        <option value="Estampers">Estampers</option>
                        <option value="Events">Events</option>
                      </select>
                      <input
                        className="form-control mb-0"
                        style={{ border: "1px solid #ddd", width: "300px" }}
                        name="state"
                        value={filter.state}
                        onChange={handleInputChange}
                        placeholder="Search State"
                        type="text"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* Display Cart Items */}
            <div className="New Collections">
              <h2>Card</h2>
              {console.log(url)}
              <div className="row">
                {cartItems.length > 0 ? (
                  cartItems.map((item, index) => (
                    <div
                      className="col-lg-3 col-md-6 col-sm-6 col-xs-12"
                      key={index}
                    >
                      <ColllectionsCard
                        src={url[0] + item?.card?.card_images?.[0]?.image}
                        name={item.card?.name}
                      />
                    </div>
                  ))
                ) : (
                  <div>No cart items available</div>
                )}
              </div>
            </div>

            <hr />
            {/* Display Filtered Users */}
            <div className="New Collections">
              <h2>User</h2>
              {filteredUsers.length === 0 ? (
                <div>No data found</div> // If no users match the filter
              ) : (
                <div className="row">
                  {filteredUsers.map((user) => (
                    <div
                      className="col-lg-3 col-md-6 col-sm-6 col-xs-12"
                      key={user.id}
                    >
                      <Link to={`/checkout/${user?.id}`}>
                        <ColllectionsCard
                          src={`${url[1]}${user?.image}`}
                          name={user?.forename + " " + user?.surname}
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <MoonLoader loading={loader} size={60} color="#6c757d" />
        </div>
      )}
    </>
  );
};

export default AllUserAndCart;
