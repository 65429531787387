/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
// import AddCard from "../Account/AddCard";
import { rootContext } from "../../context/RootContext";
import MyCards from "../Account/MyCards";
import Profile from "../Account/Profile";
import UpdateCard from "../Account/UpdateCard";
import SwapRequst from "../Swaps/SwapRequest";
import useSwapData from "../../hooks/useSwapData";
import useSubscription from "../../hooks/useSubscription";
import Orders from "../Swaps/Orders";
import useOrder from "../../hooks/useOrder";
import Fair from "../Account/Fair";
import Faq from "../Account/Faq";
import RequestedCollections from "./RequestedCollections";
import Plans from "../Plans/Plans";
import OrderRequest from "../Swaps/OrderRequest";
import AddCollabrateCard from "../Account/AddCollabrateCard";
import MyCollabrate from "../Account/MyCollabrate";
// import AddCard from "../Account/AddCard";

const AccountPanel = ({ ispro }) => {
  const { data } = useSwapData();
  const { orderItems, rquestOrderItems } = useOrder();
  const [currentTab, setCurrentTab] = useState(0);
  const { selectedTabIndex, setSelectedTabIndex, setBannerTitle } =
    useContext(rootContext);
  const { isSubscribed } = useSubscription();
  useEffect(() => {
    setCurrentTab(selectedTabIndex);
  }, [selectedTabIndex]);

  return (
    <>
      <div className="pt-2">
        <div className="container">
          <Tabs
            defaultIndex={0}
            selectedIndex={currentTab}
            onSelect={(index) => setSelectedTabIndex(index)}
          >
            <TabList>
              <Tab
                selectedClassName="t"
                onClick={() => setBannerTitle("My Account")}
              >
                Profile
              </Tab>

              <Tab onClick={() => setBannerTitle("My Cards")}>My Cards</Tab>
              <Tab onClick={() => setBannerTitle("My Collabrate")}>
                My Collabrate
              </Tab>
              {isSubscribed !== null ? (
                <>
                  {/* {ispro === "PRO" && (
                    <Tab onClick={() => setBannerTitle("Add New Card")}>
                      Add New Card
                    </Tab>
                  )} */}
                  <Tab onClick={() => setBannerTitle("Add New Cardc")}>
                    Add New Collabrate
                  </Tab>
                  <Tab
                    className="d-none"
                    onClick={() => setBannerTitle("Update Card")}
                  ></Tab>
                  <Tab>
                    <span
                      className="pr-2"
                      onClick={() => setBannerTitle("Swap Request")}
                    >
                      Swap Request{" "}
                    </span>
                    {data.length > 0 ? (
                      <span class=" start-100 ml-2  badge rounded-pill bg-primary">
                        {data.length}
                      </span>
                    ) : null}
                  </Tab>
                  <Tab>
                    <span
                      className="pr-2"
                      onClick={() => setBannerTitle("Swap Request")}
                    >
                      Order Request{" "}
                    </span>
                    {rquestOrderItems?.length > 0 ? (
                      <span class=" start-100 ml-2  badge rounded-pill bg-primary">
                        {rquestOrderItems.length}
                      </span>
                    ) : null}
                  </Tab>
                  <Tab>
                    <span
                      className="pr-2"
                      onClick={() => setBannerTitle("Orders")}
                    >
                      Orders{" "}
                    </span>
                    {orderItems.length > 0 ? (
                      <span class=" start-100 ml-2  badge rounded-pill bg-primary">
                        {orderItems.length}
                      </span>
                    ) : null}
                  </Tab>
                  <Tab>
                    {/* <Link to="/plan"
                    
                    style={{ color: "#808080" }}> */}
                    Upgrade Plan
                    {/* </Link> */}
                  </Tab>
                  <Tab onClick={() => setBannerTitle("Requested Collections")}>
                    Requested Collections
                  </Tab>
                </>
              ) : null}
              <Tab onClick={() => setBannerTitle("Fair Play")}>Fair </Tab>
              <Tab onClick={() => setBannerTitle("FAQ")}>FAQ</Tab>
            </TabList>

            <TabPanel>
              <Profile />
            </TabPanel>

            <TabPanel>
              <MyCards />
            </TabPanel>
            <TabPanel>
              <MyCollabrate />
            </TabPanel>
            {isSubscribed !== null ? (
              <>
                {/* {ispro === "PRO" && (
                  <TabPanel>
                    <AddCard />
                  </TabPanel>
                )} */}
                <TabPanel>
                  <AddCollabrateCard />
                </TabPanel>
                <TabPanel>
                  <UpdateCard />
                </TabPanel>
                <TabPanel>
                  <SwapRequst />
                </TabPanel>
                <TabPanel>
                  <OrderRequest />
                </TabPanel>
                <TabPanel>
                  <Orders />
                </TabPanel>
                <TabPanel>
                  <Plans />
                </TabPanel>
                <TabPanel>
                  <RequestedCollections />
                </TabPanel>
              </>
            ) : null}
            <TabPanel>
              <Fair />
            </TabPanel>
            <TabPanel>
              <Faq />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default AccountPanel;
