import React from "react";
import Banners from "../../Components/Banner/Banners";
import SingleChapterCollbrate from "../../Components/Collection/SingleChapterCollabrate";

const ChapterCollbarte = () => {
  return (
    <>
      <Banners name="single Chapter" />
      <SingleChapterCollbrate />
    </>
  );
};

export default ChapterCollbarte;
