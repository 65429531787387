/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import {
  //  Pencil, 
  Trash } from "lucide-react";
const ColllectionsCollabrate = ({ src,data,deleteFnc, name = "UnKnown" }) => {
  
  return (
    <>
      <div className="card_action">
        <div className="flex flex-col space-y-1">
          {/* <div
            id={`edit`}
            className="tw-cursor-pointer"
            // onClick={()=>{setIsOpen(true); setSingleData(data)}}
          >
            <Pencil color="blue"/>
          </div> */}
          <div
            id={`delete`}
            className="tw-cursor-pointer"
             onClick={()=>deleteFnc(data.id)}
          >
            <Trash  color="red"/>
          </div>
        </div>
      </div>
      <div class="">
        <div class="nft__item">
          <div class="nft__item_wrap">
            <div class="d-placeholder"></div>

            <img
              src={src}
              class="lazy"
              className="image-cover"
              alt={`alt-${name}`}
            />
          </div>
          <div class="nft__item_info">
            <h2 className="text-center w-100">{name}</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default ColllectionsCollabrate;
