import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Swal from "sweetalert2";
import { LoaderCircle } from "lucide-react";

import CollectionsTab from "./requectcollectionstab/CollectionsTab";
import { getAllCollectionsTitle } from "../../services/collectionServiceCard";
import { rootContext } from "../../context/RootContext";
import {
  addChapterService,
  addCollectionRequestCardService,
  addSubCollectionRequestCardService,
} from "../../services/cardService";
import SubCollectionsTab from "./requectcollectionstab/SubCollectionsTab";
import ChapterTab from "./requectcollectionstab/ChapterTab";

const RequestedCollections = () => {
  const {
    setChangeRequestData,
    values,
    setValues,
    show,
    setShow,
    isSub,
    setisSub,
    tabIndexChild,
    setTabIndexChild,
  } = useContext(rootContext);

  const [loader, setLoader] = useState(false);
  const [chaptermodal, setChaptermodal] = useState(false);

  const [collection, setCollection] = useState([]);

  const [valueschapter, setValueschapter] = useState({
    collection_name_chapter: "",
    sub_collection_name_chapter: "",
    chapter_name: "",
    image: null,
    imageUrl: null,
  });
  const onChange = (event) => {
    const { value, name, files } = event.target;
    if (name === "image") {
      const file = files[0];
      setValues({
        ...values,
        image: file,
        imageUrl: URL.createObjectURL(file),
      });
    } else setValues({ ...values, [name]: value });
  };
  const onChangeChapter = (event) => {
    const { value, name, files } = event.target;
    if (name === "image") {
      const file = files[0];
      setValueschapter({
        ...valueschapter,
        image: file,
        imageUrl: URL.createObjectURL(file),
      });
    } else setValueschapter({ ...valueschapter, [name]: value });
  };
  const handleClose = () => {
    setChaptermodal(false);
    setShow(false);
  };
  const handleShow = () => {
    // alert('')
    // setTabIndexChild(0)
    setValues({
      collection_name: "",
      sub_collection_name: "",
      image: null,
      imageUrl: null,
    });
    setShow(true);
  };

  const allCollectionsData = async () => {
    // setLoader(true)
    const result = await getAllCollectionsTitle();

    if (result.categorycardCollectionTitle) {
      setCollection(result.categorycardCollectionTitle);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const Addcollection = async () => {
    setLoader(true);
    let url = "";
    const formdata = new FormData();
    formdata.append("collection_name", values.collection_name);
    if (typeof values.image !== "string") {
      formdata.append("image", values.image);
    }
    if ("_method" in values) {
      url = `user/collectionrequests/${values.id}`;
      formdata.append("_method", "PUT");
    } else {
      url = "user/collectionrequests";
    }
    const result = await addCollectionRequestCardService(url, formdata);
    setTabIndexChild(0);
    if (result.collectionRequest) {
      setTabIndexChild(0);
      handleClose();
      Swal.fire({
        icon: "success",
        title: result.message,
      });
      let x = Math.random();
      setChangeRequestData(x);
      setValues({
        collection_name: "",
        sub_collection_name: "",
        image: null,
        imageUrl: null,
      });
      allCollectionsData();
      setLoader(false);
    } else {
      Swal.fire({
        icon: "error",
        title: result.errors[0],
      });
      setLoader(false);
    }
  };
  const AddSubcollection = async () => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("collection_name", values.collection_name);
    // formdata.append("image", values.image);
    formdata.append("sub_collection_name", values.sub_collection_name);
    let url = "";
    if (typeof values.image !== "string") {
      formdata.append("image", values.image);
    }
    if ("_method" in values) {
      url = `user/subcollectionrequest/${values.id}`;
      formdata.append("_method", "PUT");
    } else {
      url = "user/subcollectionrequest";
    }
    const result = await addSubCollectionRequestCardService(url, formdata);
    if (result.subCollectionRequest) {
      setTabIndexChild(1);
      handleClose();
      Swal.fire({
        icon: "success",
        title: result.message,
      });
      const x = Math.random();
      setChangeRequestData(x);
      setValues({
        collection_name: "",
        sub_collection_name: "",
        image: null,
        imageUrlUrl: null,
      });
      setLoader(false);
    } else {
      Swal.fire({
        icon: "error",
        title: result.errors[0],
      });
    }
  };
  console.log(valueschapter, "valueschapter");
  const AddChapter = async () => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("collection_name", valueschapter.collection_name_chapter);
    formdata.append(
      "sub_collection_name",
      valueschapter.sub_collection_name_chapter
    );
    formdata.append("chapter_name", valueschapter.chapter_name);
    let url = "";
    if (typeof valueschapter.image !== "string") {
      formdata.append("image", valueschapter.image);
    }
    if ("_method" in valueschapter) {
      url = `user/chapterrequests/${valueschapter.id}`;
      formdata.append("_method", "PUT");
    } else {
      url = "user/chapterrequests";
    }
    const result = await addChapterService(url, formdata);
    if (result.chapterRequest) {
      setTabIndexChild(2);
      handleClose();
      Swal.fire({
        icon: "success",
        title: result.message,
      });
      const x = Math.random();
      setChangeRequestData(x);
      setChaptermodal(false);
      setValueschapter({
        collection_name_chapter: "",
        sub_collection_name_chapter: "",
        chapter_name: "",
        image: null,
        imageUrlUrl: null,
      });
      setLoader(false);
    } else {
      Swal.fire({
        icon: "error",
        title: result.errors,
      });
    }
  };
  const HandleChapter = () => {
    setChaptermodal(true);
    setValueschapter({
      collection_name_chapter: "",
      sub_collection_name_chapter: "",
      chapter_name: "",
      image: null,
      imageUrl: null,
    });
  };
  useEffect(() => {
    allCollectionsData();
  }, []);
  return (
    <>
      <h1 className="pt-3 text-black">
        Requested{" "}
        {tabIndexChild === 0
          ? "Collection"
          : tabIndexChild === 1
          ? "Sub Collection"
          : "Chapter"}
      </h1>

      <div className="position-relative my-3">
        <div className="text-end">
          <button
            className="btn btn-primary"
            onClick={tabIndexChild === 2 ? HandleChapter : handleShow}
          >
            Request for a{" "}
            {tabIndexChild === 0
              ? "Collection"
              : tabIndexChild === 1
              ? "Sub Collection"
              : "Chapter"}
          </button>
        </div>
        <div className="container">
          <div className="container">
            <Tabs
              defaultIndex={0}
              selectedIndex={tabIndexChild}
              onSelect={(index) => setTabIndexChild(index)}
            >
              <TabList>
                <Tab> Collection</Tab>
                <Tab>Sub Collection</Tab>
                <Tab>Chapter</Tab>
              </TabList>

              <TabPanel>
                <CollectionsTab />
              </TabPanel>
              <TabPanel>
                <SubCollectionsTab />
              </TabPanel>
              <TabPanel>
                <ChapterTab
                  setValueschapter={setValueschapter}
                  setChaptermodal={setChaptermodal}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>

        <Modal
          show={tabIndexChild === 2 ? chaptermodal : show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Request for a{" "}
              {tabIndexChild === 0
                ? "Collection"
                : tabIndexChild === 1
                ? "Sub Collection"
                : "Chapter"}
            </Modal.Title>
          </Modal.Header>
          {tabIndexChild !== 2 && (
            <Modal.Body>
              <div className="text-end mb-3">
                <button
                  className="btn btn-primary"
                  onClick={() => setisSub(!isSub)}
                >
                  Request for a{" "}
                  {tabIndexChild === 0
                    ? "Collection"
                    : tabIndexChild === 1
                    ? "Sub Collection"
                    : "Chapter"}
                </button>
              </div>
              {tabIndexChild === 0 ? (
                <>
                <input
                  placeholder="Enter New Collection Name"
                  className="form-control mb-3"
                  onChange={onChange}
                  name="collection_name"
                  value={values.collection_name}
                  />
                  </>
              ) : (
                <>
                  <select
                    className="form-control mb-3"
                    name="collection_name"
                    value={values.collection_name}
                    onChange={onChange}
                  >
                    <option selected hidden>
                      Select Collection Name...
                    </option>
                    {collection.length > 0 &&
                      collection.map((data) => (
                        <option value={data.name}>{data.name}</option>
                      ))}
                  </select>
                  <input
                    name="sub_collection_name"
                    value={values.sub_collection_name}
                    onChange={onChange}
                    placeholder="Enter New Sub-Collection Name"
                    className="form-control"
                  />
                </>
              )}
              <input
                className="form-control mt-2"
                onChange={onChange}
                type="file"
                name="image"
              />

              {values.image && (
                <img
                  src={values.imageUrl}
                  alt="imge"
                  className="w-100 rounded"
                />
              )}
            </Modal.Body>
          )}
          {tabIndexChild === 2 && (
            <Modal.Body>
              <input
                placeholder="Enter Collection Name"
                className="form-control mb-3"
                onChange={onChangeChapter}
                name="collection_name_chapter"
                value={valueschapter.collection_name_chapter}
              />
              <input
                placeholder="Enter Sub Collection Name"
                className="form-control mb-3"
                onChange={onChangeChapter}
                name="sub_collection_name_chapter"
                value={valueschapter.sub_collection_name_chapter}
              />
              <input
                placeholder="Enter Chapter Name"
                className="form-control mb-3"
                onChange={onChangeChapter}
                name="chapter_name"
                value={valueschapter.chapter_name}
              />
              <input
                className="form-control mb-3"
                onChange={onChangeChapter}
                type="file"
                name="image"
              />
              {valueschapter.imageUrl && (
                <img
                  src={valueschapter.imageUrl}
                  alt="imge"
                  className="w-100 rounded"
                />
              )}
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={loader}
              onClick={
                tabIndexChild === 0
                  ? Addcollection
                  : tabIndexChild === 1
                  ? AddSubcollection
                  : AddChapter
              }
            >
              {loader ? "Requesting..." : "Request"}{" "}
              {loader && <LoaderCircle />}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default RequestedCollections;
