import React from "react";
import Banners from "../../Components/Banner/Banners";
import AllUserAndCart from "../../Components/Collection/AlluseradnCart";

const UserAndCart = () => {
  return (
    <>
      <Banners name="All User And Cart" />
      <AllUserAndCart />
    </>
  );
};

export default UserAndCart;
