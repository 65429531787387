/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import {
  addCartService,
  deleteCartsService,
  getCartsService,
} from "../services/cartService";
import { rootContext } from "../context/RootContext";

const useCart = () => {
  // const { quantities } = useContext(rootContext);
  const [cartItems, setCartItems] = useState([]);
  const { setCartItemsLength } = useContext(rootContext);
  const [loading, setLoading] = useState(false);
  const [url, seturl] = useState([]);

  const [error, setError] = useState();

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getCartsService();
      if (result && result.cart) {
        seturl([
          result?.imagePathCard,
          result.imagePathUser,
          result?.imagePath,
        ]);
        setCartItems(result.cart);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();

    // Clean up function if needed
    return () => {
      // Cleanup code here
    };
  }, []);
  useEffect(() => {
    setCartItemsLength(cartItems.length);
  }, [cartItems]);
  const addToCart = async (newItem, id) => {
    // console.log(quantities[id],'quantity')
    try {
      const data = await addCartService(
        newItem.id,
        newItem.sale_price,
        // 200,
        id
      );
      console.log("get item is is", data.cartData);
      await fetchData();
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };
  const addToCartone = async (newItem, id) => {
    // console.log(quantities[id],'quantity')
    try {
      const data = await addCartService(
        newItem.id,
        newItem.sale_price,
        // 200,
        id
      );
      console.log("get item is is", data.cartData);
      await fetchData();
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const removeFromCart = async (itemId) => {
    try {
      const data = await deleteCartsService(itemId);

      await fetchData();
      setError(data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const updateToCart = async (id, price, qty) => {
    try {
      const data = await addCartService(id, price, qty);
      setError(data);
      await fetchData();
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // Helper function to persist cart items to local storage
  const persistCart = () => {
    if (cartItems.length > 0) {
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }
  };

  // Call persistCart after any cart item changes for persistence
  useEffect(() => {
    persistCart();
  }, [cartItems]);

  return {
    cartItems,
    addToCart,
    removeFromCart,
    addToCartone,
    updateToCart,
    url,
    loading,
    error,
    fetchData,
  };
};

export default useCart;
