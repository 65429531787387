/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ColllectionsCard from "../Home/ColllectionsCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import {
  getAllChapterSlug,
  getAllChapterSlugWithoutToken,
} from "../../services/subCollectionsService";

const SingleChapter = () => {
  const { name } = useParams();
  const [data, setData] = useState([]);
  const { state } = useLocation();
  const Navigate = useNavigate();
  //   const [url, setUrl] = useState("");
  const [loader, setLoader] = useState(false);
  const HandleNavigate = (item) => {
    if (state === "istoken") {
      Navigate(`/detail/${item.slug}`);
    } else {
      Navigate(`/details/${item.slug}`);
    }
  };
  const allSubCollectionsData = async (name) => {
    setLoader(true);
    const result = await getAllChapterSlug(name);
    if (result.chapters) {
      setData(result.chapters);
      setLoader(false);
      //   setUrl(result.imagePath);
    } else {
      setLoader(false);
    }
  };
  const allSubCollectionsDataWihtoutToken = async (name) => {
    setLoader(true);
    const result = await getAllChapterSlugWithoutToken(name);
    if (result.chapters) {
      setData(result.chapters);
      setLoader(false);
      //   setUrl(result.imagePath);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (state === "istoken") {
      allSubCollectionsDataWihtoutToken(name);
    } else {
      allSubCollectionsData(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);
  return (
    <>
      {!loader ? (
        <div className="pt-5">
          <div class="container">
            <div class="row fadeIn">
              <div class="col-lg-12">
                <div class="items_filter w-100">
                  <form class="row form-dark w-100" id="form_quick_search">
                    <div class="col text-center w-100">
                      <input
                        class="form-control "
                        id="name_1"
                        name="name_1"
                        placeholder="search item here..."
                        type="text"
                      />{" "}
                      <a href="#" id="btn-submit">
                        <i class="fa fa-search bg-color-secondary"></i>
                      </a>
                      <div class="clearfix"></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* new collection */}

            <div className="New Collections">
              <h2>Chapter</h2>
              <div className="row">
                {data?.map((item, index) => (
                  <div
                    class=" col-lg-3 col-md-6 col-sm-6 col-xs-12"
                    onClick={() => HandleNavigate(item)}
                  >
                    {/* <Link to={`/detail/${item.slug}`}> */}
                    <ColllectionsCard
                      src={`${item.image_url}`}
                      name={item.name}
                    />
                    {/* </Link> */}
                  </div>
                ))}
              </div>
            </div>

            <div class="col-md-12 text-center mb-5">
              <a href="#" id="loadmore" class="btn-main  fadeInUp lead">
                Load more
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex  justify-content-center align-items-center vh-100">
          <MoonLoader size={90} loading={loader} color="#333" />
        </div>
      )}
    </>
  );
};

export default SingleChapter;
