import React from "react";
import Banners from "../../Components/Banner/Banners";
import SingleChapter from "../../Components/Collection/SingleChapter";

const ChapterSlug = () => {
  return (
    <>
      <Banners name="single Chapter" />
      <SingleChapter />
    </>
  );
};

export default ChapterSlug;
