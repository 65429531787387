import React from "react";
import Banners from "../../Components/Banner/Banners";
import ChatUi from "../../Components/ChatUi/ChatUi";

export default function Chat() {
  return (
    <>
      {" "}
      <Banners name={'Chat'} />
      {/* Add your chat component here */}
      <ChatUi/>
    </>
  );
}
