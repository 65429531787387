import React from "react";
import { Modal } from "react-bootstrap";

export default function ViewUploadCard({
  setviewuploadCard,
  viewuploadCard,
  imageurl,
  data,
}) {
  return (
    <Modal
      show={viewuploadCard}
      onHide={() => setviewuploadCard(false)}
      animation={false}
      dialogClassName="bg-white w-75" // Adjust the width as needed
    >
      <Modal.Header className="border-bottom-0 pb-0" closeButton>
        <Modal.Title as="h4" className="w-100 text-center p-0">
          View Uploaded Card
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="text-dark">
        <div className="container">
          <div className="row justify-content-center">
            {/* Image Section */}
            <div className="col-12 text-center mb-4">
              <img
                src={imageurl + data?.image || "path/to/default/image.jpg"} // Assuming 'data' contains the image URL
                alt="Uploaded Card"
                className="img-fluid"
                style={{ maxHeight: "300px", objectFit: "contain" }}
              />
            </div>

            {/* Data Fields Section */}
            <div className="col-12">
              <div className="mb-3 d-flex justify-content-between">
                <h5>Name:</h5>
                <p>{data?.name || "No data available"}</p>
              </div>
            </div>

            {/* Close Button Section */}
            <div className="col-12 text-center mt-4">
              <button
                className="btn btn-secondary"
                onClick={() => setviewuploadCard(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
