/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useEffect, useState } from 'react'
import ColllectionsCard from '../Home/ColllectionsCard'
import { Link, useParams } from 'react-router-dom'
import { MoonLoader } from 'react-spinners'
import { getAllSubCollectionsService } from '../../services/subCollectionsService'

const SingleCollectionActive = () => {
    const {name} = useParams();
    const [data,setData]= useState([])
    const [url,setUrl]= useState('')
    const [loader,setLoader] = useState(false)

    const allSubCollectionsData= async (name)=>{
        setLoader(true)
          const result =  await getAllSubCollectionsService(name)
          if(result.cardCollectionSubTitle){
         
            setData(result.cardCollectionSubTitle)
            setLoader(false)
            setUrl(result.imagePath)
          }
          else{
            setLoader(false)
          }
    }
    useEffect(()=>{

        allSubCollectionsData(name);
        console.log("the name is",name)
        
    },[name])
    return (
        <>
        {!loader ?
            <div className='pt-5'>
                <div class="container">
                    <div class="row fadeIn">
                        <div class="col-lg-12">
                            <div class="items_filter w-100">
                                <form  class="row form-dark w-100" id="form_quick_search" >
                                    <div class="col text-center w-100">
                                        <input class="form-control " id="name_1" name="name_1" placeholder="search item here..." type="text" /> <a href="#" id="btn-submit"><i class="fa fa-search bg-color-secondary"></i></a>
                                        <div class="clearfix"></div>
                                    </div>
                                </form>



                            </div>
                        </div>
                    </div>
                    {/* new collection */}
                  
                    <div className='New Collections'>
                        <h2>Sub Collections</h2>
                        <div className='row'>
                           {data?.map((item,index)=>(
                            <div class=" col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                <Link to={`/chapter/${item.slug}`}>
                                <ColllectionsCard  src={`${url}${item.image}`} name={item.name}/>
                                </Link>
                            </div>
                           ))}
                            

                        </div>
                    </div>


                    <div class="col-md-12 text-center mb-5">
                        <a href="#" id="loadmore" class="btn-main  fadeInUp lead">Load more</a>
                    </div>
                </div>
            </div>

            :
            <div className='d-flex  justify-content-center align-items-center vh-100'>

                <MoonLoader size={90}  loading={loader} color="#333" />
            </div>
        }

        </>
    )
}

export default SingleCollectionActive