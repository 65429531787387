/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  ListGroup,
  Image,
} from "react-bootstrap";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

import { axiosInstance } from "../../utils/axiosHelper";

export default function ChatUi() {
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [messages, setMessages] = useState([]);
  const userId = JSON.parse(localStorage.getItem("user"));
  const [inputMessage, setInputMessage] = useState("");
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  const handleSendMessage = async () => {
    if (inputMessage.trim()) {
      const newMessage = {
        message: inputMessage,
        // sender: "second",
        user_id_one: userId?.id,
        time: "Just Now",
        avatar: "https://cdn-icons-png.flaticon.com/512/149/149071.png",
      };
      setMessages([...messages, newMessage]);
      setInputMessage("");
    }
    try {
      const formdata = new FormData();
      formdata.append("message", inputMessage);
      formdata.append("order_id", state?.id);

      const result = await axiosInstance.post(
        "user/order-conversation",
        formdata
      );
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleSendMessageSecond = () => {
  //   if (inputMessage.trim()) {
  //     const newMessage = {
  //       message: inputMessage,
  //       sender: "second",
  //       user_id_one: userId?.id,
  //       time: new Date().toLocaleTimeString(),
  //       avatar: "https://i.pravatar.cc/50?img=2",
  //     };
  //     setMessages([...messages, newMessage]);
  //     setInputMessage("");
  //   }
  // };
  const GetMessage = async () => {
    try {
      const result = await axiosInstance.get(
        `user/order-conversation?order_id=${state?.id}`
      );
      console.log(result?.data?.orderConversation);
      setMessages(result.data.orderConversation);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container className="my-4" style={{ maxWidth: "600px" }} id="scrol">
      <Row>
        <Col>
          <Card
            className="shadow-lg rounded-3"
            style={
              {
                // backgroundImage:
                //   'url("https://w0.peakpx.com/wallpaper/818/148/HD-wallpaper-whatsapp-background-cool-dark-green-new-theme-whatsapp.jpg")',
                // backgroundSize: "cover",
              }
            }
          >
            <Card.Body>
              {/* <button> */}
              <div className="d-flex">
                <div className="mr-3 mt-2 cursor-pointer" style={{cursor:"pointer"}} onClick={() => navigate(-1)}>
                  <IoMdArrowRoundBack size={30} />
                </div>
                <div>
                  <Image
                    src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                    roundedCircle
                    width={40}
                    height={40}
                  />
                </div>
              </div>
              {/* </button> */}
              <h1 className="text-center text-primary mb-4">Chat</h1>
              <div
                className="messages-container"
                style={{ height: "400px", overflowY: "scroll" }}
              >
                <ListGroup variant="flush">
                  {messages.map((message, index) => (
                    <ListGroup.Item
                      key={index}
                      className={`d-flex ${
                        message.user_id_one === userId?.id
                          ? "justify-content-end"
                          : ""
                      }`}
                    >
                      <div
                        className={`d-flex ${
                          message.user_id_one === userId?.id
                            ? "flex-row-reverse"
                            : ""
                        }`}
                      >
                        <div className="d-flex align-items-center">
                          <Image
                            src={message.avatar}
                            roundedCircle
                            width={35}
                            height={35}
                          />
                        </div>
                        <div className="ms-2">
                          <Card
                            className={`message-card ${
                              message.user_id_one === userId?.id
                                ? "user-message"
                                : "second-message"
                            }`}
                          >
                            <Card.Body className="p-0">
                              <p
                                style={{ height: "fit-content", margin: "0px" }}
                              >
                                {message?.message}
                                <span
                                  className="position-relative"
                                  style={{
                                    fontSize: "10px",
                                    float: "right",
                                    bottom: "-2px",
                                    index: "10",
                                    height: "fit-content",
                                    paddingLeft: "4px",
                                  }}
                                >
                                  {message?.created_at_human || message?.time}
                                </span>
                              </p>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                  <div ref={messagesEndRef} />
                </ListGroup>
              </div>
            </Card.Body>
          </Card>

          <InputGroup className="mt-3">
            <Form.Control
              type="text"
              placeholder="Type a message..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              className="message-input"
            />
            <Button
              variant="primary"
              onClick={handleSendMessage}
              className="send-btn"
            >
              Send
            </Button>
            {/* <Button
              variant="secondary"
              onClick={handleSendMessageSecond}
              className="send-btn"
            >
              Send (Second)
            </Button> */}
          </InputGroup>
        </Col>
      </Row>
    </Container>
  );
}
