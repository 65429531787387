/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { addCartService, deleteCartsService } from "../services/cartService";
import {
  getOrderRequestService,
  getOrderService,
} from "../services/orderService";
import {
  ChangeDealCardService,
  ChangeStatusCardService,
} from "../services/cardService";

const useOrder = () => {
  const [orderItems, setOrderItems] = useState([]);
  const [rquestOrderItems, setRequestOrderItems] = useState([]);
  const [url, seturl] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getOrderService();
      if (result && result.orders) {
        // seturl([
        //   result?.imagePathCard,
        //   result.imagePathUser,
        //   result?.imagePath,
        // ]);
        seturl(result?.imagePath)
        setOrderItems(result.orders);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchRequestData = async () => {
    try {
      setLoading(true);
      const result = await getOrderRequestService();
      if (result && result.orders) {
        setRequestOrderItems(result.orders);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRequestData();
    fetchData();

    // Clean up function if needed
    return () => {
      // Cleanup code here
    };
  }, []);

  const addToCart = async (newItem) => {
    try {
      const data = await addCartService(newItem.id, newItem.sale_price, 1);
      setError(data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const removeFromCart = async (itemId) => {
    try {
      const data = await deleteCartsService(itemId);
      fetchData();
      setError(data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const updateToCart = async (id, price, qty) => {
    try {
      const data = await addCartService(id, price, qty);
      setError(data);
      fetchData();
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOrderStatus = async (id, status) => {
    const result = await ChangeStatusCardService(id, status);
    if (result.order) {
      fetchData();
      fetchRequestData();
    }
    return result;
  };
  const handleDealStatus = async (id, status) => {
    const result = await ChangeDealCardService(id, status);
    if (result.order) {
      fetchData();
      fetchRequestData();
    }
    return result;
  };

  // Helper function to persist cart items to local storage
  const persistCart = () => {
    if (orderItems.length > 0) {
      localStorage.setItem("cartItems", JSON.stringify(orderItems));
    }
  };

  // Call persistCart after any cart item changes for persistence
  useEffect(() => {
    persistCart();
  }, [orderItems]);

  return {
    orderItems,
    addToCart,
    removeFromCart,
    handleOrderStatus,
    updateToCart,
    loading,
    error,
    setLoading,
    handleDealStatus,
    rquestOrderItems,
    url,
  };
};

export default useOrder;
