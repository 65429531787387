import React, { useEffect, useState } from "react";
import { getAllSubCollectionsPublicService } from "../../services/collectionsService";
import ColllectionsCard from "../Home/ColllectionsCard";
import { Link } from "react-router-dom";

export default function AvailableSubCollabrate() {
  const [data, setData] = useState([]);
  const [url, setUrl] = useState("");
  const [, setLoader] = useState(false);

  const allCollectionsData = async () => {
    setLoader(true);
    const result = await getAllSubCollectionsPublicService();
    if (result.cardCollectionSubTitle) {
      setData(result.cardCollectionSubTitle);
      setLoader(false);
      setUrl(result.imagePath);
    } else {
      setLoader(false);
    }
  };
  useEffect(() => {
    allCollectionsData();
  }, []);
  return (
    <>
      <div className="row">
        <h2> Available Sub Collabrate Album</h2>
        {data.length > 0 &&
          data.slice(0, 8)?.map((item, index) => (
            <div class=" col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <Link to={`/chapterCollbarte/${item.slug}`}>
                <ColllectionsCard
                  src={`${url}${item.image}`}
                  name={item.name}
                />
              </Link>
            </div>
          ))}
      </div>
    </>
  );
}
