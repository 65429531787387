import { axiosInstance } from "../utils/axiosHelper"

export const addCartService =async(id,price,qty)=>{
    const formdata = new FormData();

    formdata.append('card_id[]',id)
    formdata.append('price[]',price)
    formdata.append('qty[]',qty)
   return await axiosInstance.post('user/carts',formdata)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const getSinglePlansService =async(id)=>{
   return await axiosInstance.get('user/plans/'+id)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const getCartsService =async()=>{
   return await axiosInstance.get('user/carts')
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}
export const getCartsServiceWihoutToken =async()=>{
   return await axiosInstance.get('carts')
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}

export const deleteCartsService =async(id)=>{
   return await axiosInstance.delete(`user/carts/${id}`)
   .then((res)=>{
    return res.data
   })
   .catch((err)=>{
    return err.response.data
   })
}

