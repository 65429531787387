import React, { useState } from "react";
import PlanCard from "./PlanCard";
import usePlans from "../../hooks/usePlans";
import { ClipLoader } from "react-spinners";
import {
  addFreeSubecriptions,
  addSubecriptions,
} from "../../services/subscriptionService";
import useSubscription from "../../hooks/useSubscription";
import ProtectionPlan from "./ProtectionPlan";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function Plans() {
  const { plans, loading } = usePlans();
  const [buttonLoader, setButtonLoader] = useState(null);
  const { isSubscribed } = useSubscription();
  const navigate = useNavigate();
  const hanldePlanSubscriptions = async (id, upgrade, price) => {
    setButtonLoader(id);
    if (price !== 0) {
      const response = await addSubecriptions(id, upgrade);
      if (response.link) {
        window.location.href = response.link;
        setButtonLoader(null);
        return true;
      }
    } else {
      const response = await addFreeSubecriptions(id);
      console.log("free response is", response);
      if (response.status) {
        Swal.fire({
          icon: "success",
          title: response.message,
          showConfirmButton: true,
          timer: 15000,
        });
        setButtonLoader(null);
        return navigate("/account");
      }
    }
  };
  return (
    <>
      {!loading ? (
        <div className="plan container  ">
          <ProtectionPlan />
          <div className="row pt-4">
            {plans &&
              plans.map((item, index) => (
                <div
                  key={index}
                  className="col-lg-4 col-md-6 mb-3 position-relative"
                >
                  {isSubscribed?.plan?.name === item.name ? (
                    <div className="current-plan">Current Plan</div>
                  ) : null}

                  <PlanCard
                    name={item.name}
                    description={item.description}
                    duration={item.duration}
                    period={item.period}
                    price={item.price}
                    hanldePlanSubscriptions={hanldePlanSubscriptions}
                    id={item.id}
                    buttonLoader={buttonLoader}
                  />
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className="container vh-40 d-flex justify-content-center align-items-center">
          <ClipLoader size={40} color="#333" />
        </div>
      )}
    </>
  );
}
