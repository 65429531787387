import React, { useContext, useEffect, useState } from "react";

import { ClipLoader } from "react-spinners";
// import ColllectionsCardUser from "../Home/ColllectionsCardUser";
import Swal from "sweetalert2";
import {
  deletechapterrequestsCardRequest,
  getAllchapterrequestsService,
} from "../../../services/collectionServiceCard";
import { rootContext } from "../../../context/RootContext";
import ChapterCard from "./ChapterCard";
export default function ChapterTab({ setChaptermodal, setValueschapter }) {
  const { changRequestData, setisSub } = useContext(rootContext);
  const [data, setData] = useState([]);
  const [, setUrl] = useState("");
  const [loader, setLoader] = useState(true);

  const allCollectionsData = async () => {
    // setLoader(true)
    const result = await getAllchapterrequestsService();
    console.log("the result is", result);
    if (result) {
      setData(result.chapterRequests);
      setLoader(false);
      setUrl(result.imagePath);
    } else {
      setLoader(false);
    }
  };

  const openDeleteConfirmModal = async (id) => {
    // alert(row.original.id)

    Swal.fire({
      title: "Do you want to Delete a Application",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setLoader(true);

        const reuslt = await deletechapterrequestsCardRequest(id);
        console.log("the response is", result);
        if (reuslt) {
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Application has been deleted successfully.",
          });
          setLoader(false);
          allCollectionsData();
        } else {
          // setIsUpdatingUser(false);
          // setIsLoadingUsers(false);
          setLoader(false);
        }
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  // delete card end

  const editCard = (data) => {
    const updatedData = {
      id: data?.id,
      collection_name_chapter: data.collection_name,
      sub_collection_name_chapter: data.sub_collection_name,
      chapter_name: data.chapter_name,
      image: data?.image_url,
      imageUrl: data.image_url,
      _method: "PUT",
    };

    setisSub(true);
    setValueschapter(updatedData);

    setChaptermodal(true);
  };
  // change status end
  useEffect(() => {
    allCollectionsData();
  }, []);
  useEffect(() => {
    allCollectionsData();
  }, [changRequestData]);
  return (
    <div>
      {!loader ? (
        <div className="New Collections">
          <h2> Chapter</h2>

          <div className="row">
            {data?.map((item, index) => (
              <div class=" col-lg-3 col-md-6 col-sm-6 col-xs-12">
                {console.log("the log file is", item)}
                <ChapterCard
                  src={`${item?.image_url ? item?.image_url : null}`}
                  data={item}
                  deleteFnc={openDeleteConfirmModal}
                  editFnc={editCard}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="vh-50 d-flex justify-content-center align-items-center">
          <ClipLoader loading={loader} color="#333" size={50} />
        </div>
      )}
    </div>
  );
}
