import { axiosInstance } from "../utils/axiosHelper";

export const addUserCard = async (data) => {
  const formdata = new FormData();
  formdata.append("name", data.name);
  formdata.append(
    "card_collection_title_id",
    data?.card_collection_title_id?.split("&")[0]
  );
  formdata.append(
    "card_collection_sub_title_id",
    data.card_collection_sub_title_id.split("&")[0]
  );
  formdata.append("card_type_id", data.card_type_id.split("&")[0]);
  formdata.append("sale_price", data.sale_price);
  formdata.append("is_for_sale", data.is_for_sale.split("&")[0]);
  formdata.append("chapter_id", data?.chapter_id?.split("&")[0]);
  if (data.image !== null) {
    formdata.append("image[0]", data.image);
  }
  formdata.append("quantity", data.quantity);
  formdata.append("deal", data.deal);
  return await axiosInstance
    .post("user/cards", formdata)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const addUserCollbrate = async (data) => {
  const formdata = new FormData();
  formdata.append("name", `${data.name} 192022`);
  formdata.append(
    "card_collection_title_id",
    data.card_collection_title_id.split("&")[0]
  );
  formdata.append(
    "card_collection_sub_title_id",
    data.card_collection_sub_title_id.split("&")[0]
  );
  formdata.append("chapter_id", data?.chapter_id?.split("&")[0]);

  formdata.append("card_type_id", data.card_type_id.split("&")[0]);
  if (data.image !== null) {
    formdata.append("image[0]", data.image);
  }
  return await axiosInstance
    .post("user/cardCollabrate", formdata)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const addUploadCard = async (data, id) => {
  const formdata = new FormData();
  formdata.append("name", `${data.name}`);
  formdata.append(
    "card_collection_title_id",
    data.card_collection_title_id.split("&")[0]
  );
  formdata.append(
    "card_collection_sub_title_id",
    data.card_collection_sub_title_id.split("&")[0]
  );
  formdata.append("order_id", id);
  formdata.append("card_type_id", data.card_type_id.split("&")[0]);
  if (data.image !== null) {
    formdata.append("image", data.image);
  }
  return await axiosInstance
    .post("user/upload-order-card", formdata)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const getAllCardsService = async () => {
  return await axiosInstance
    .get("user/cards")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const changeCardStatus = async (status, id) => {
  const formdata = new FormData();
  formdata.append("is_active", status);
  formdata.append("id", id);
  return await axiosInstance
    .post("user/cards/changeStatus", formdata)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const deleteUserCard = async (id) => {
  return await axiosInstance
    .delete(`user/cards/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const DeletCollbrate = async (id) => {
  return await axiosInstance
    .delete(`user/cardCollabrate/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const addCollectionRequestCardService = async (url, data) => {
  return await axiosInstance
    .post(url, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const addSubCollectionRequestCardService = async (url, data) => {
  return await axiosInstance
    .post(url, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const addChapterService = async (url, data) => {
  return await axiosInstance
    .post(url, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const updateUserCard = async (data) => {
  const formdata = new FormData();
  formdata.append("name", data.name);
  formdata.append("deal", data.deal);
  if (typeof data.card_collection_title_id === "string") {
    formdata.append(
      "card_collection_title_id",
      data.card_collection_title_id.split("&")[0]
    );
  } else {
    formdata.append("card_collection_title_id", data.card_collection_title_id);
  }

  if (typeof data.card_collection_sub_title_id === "string") {
    formdata.append(
      "card_collection_sub_title_id",
      data.card_collection_sub_title_id.split("&")[0]
    );
  } else {
    formdata.append(
      "card_collection_sub_title_id",
      data.card_collection_sub_title_id
    );
  }

  if (typeof data.card_type_id === "string") {
    formdata.append("card_type_id", data.card_type_id.split("&")[0]);
  } else {
    formdata.append("card_type_id", data.card_type_id);
  }

  if (typeof data.is_for_sale === "string") {
    formdata.append("is_for_sale", data.is_for_sale.split("&")[0]);
  } else {
    formdata.append("is_for_sale", data.is_for_sale);
  }

  console.log("thie image is", data.image === undefined);
  if (typeof data.image !== "string" && data.image !== undefined) {
    formdata.append("image[0]", data.image);
  }
  formdata.append("sale_price", data.sale_price);
  formdata.append("quantity", data.quantity);
  formdata.append("_method", "PUT");
  return await axiosInstance
    .post(`user/cards/${data.id}`, formdata)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const cardBySubCollectionsSlugById = async (slug, id) => {
  return await axiosInstance
    .get(`user/cards?chapterSlug=${slug}&user_id=${id}&is_for_sale=1`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const cardBySubCollectionsSlugByIdActive = async (slug) => {
  return await axiosInstance
    .get(`user/cards?chapterSlug=${slug}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const cardBySubCollectionsSlug = async (slug) => {
  return await axiosInstance
    .get(`cards?chapterSlug=${slug}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const swapCardsService = async (slug, id) => {
  return await axiosInstance
    .get(`user/cardswaps?cardCollectionSubTitleSlug=${slug}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const cardSwapMessage = async (data) => {
  return await axiosInstance
    .post(`user/cardswaps`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const makeSwapDealService = async (user_id) => {
  const formdata = new FormData();
  formdata.append("swap_user_id", user_id);
  return await axiosInstance
    .post(`user/orders/swap-deal`, formdata)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
export const ChangeStatusCardService = async (order_id, status) => {
  const formdata = new FormData();
  formdata.append("order_id", order_id);
  formdata.append("upload_card", status);
  return await axiosInstance
    .post(`user/orders/uploaded-card`, formdata)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};

export const ChangeDealCardService = async (order_id, status) => {
  const formdata = new FormData();
  formdata.append("order_id", order_id);
  formdata.append("status", status);
  return await axiosInstance
    .post(`user/orders/status`, formdata)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err?.response?.data;
    });
};
