import React from "react";
import Banners from "../../Components/Banner/Banners";
import CartDetail from "../../Components/Collection/CartDetail";
import { useParams } from "react-router-dom";

const CartCheckout = () => {
  const { id } = useParams();
  return (
    <>
      <Banners name="Checkout Page" />
      <CartDetail  userId={id}/>
    </>
  );
};

export default CartCheckout;
